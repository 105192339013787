<template>
  <div>
    <ComonBreadcrumb 
      :breadcrumb="[
        { label: 'início', path: '/' }, 
        { label: 'Checklist', path: '/painel/checklist' }
      ]"
    />
    <div class="row">
      <div class="column large-12">
        <h1 class="checklist-titulo">Checklist do Projeto</h1>
      </div>
      <div class="column large-6 container-filtros">
        <div class="filter-wrapper">
          <p class="filter-label">
            Filtrar por:
          </p>
          <CommonSelect 
            :options="taskSectors"
            @change="changeCategoryFilter"
            id="checklistFiltro"
          />
        </div>
        <ChecklistSearch @change="changeSearch" />
      </div>
      <div class="column large-12">
        <ChecklistTasks 
          :currentFilter="currentFilter" 
          :searchText="searchText"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ComonBreadcrumb from '../components/common/ComonBreadcrumb.vue';
import CommonSelect from '../components/common/CommonSelect.vue';
import ChecklistTasks from '../components/checklist/ChecklistTasks.vue';
import ChecklistSearch from '../components/checklist/ChecklistSearch.vue';

export default {
  components: {
    ComonBreadcrumb,
    CommonSelect,
    ChecklistTasks,
    ChecklistSearch,
  },
  data() {
    return {
      searchText: '',
      taskSectors: [
        {
          label: 'Todas as tarefas',
          hash: 'all',
          selected: true,
          available: true,
          className: ''
        },
        {
          label: 'Responsável wap.store',
          hash: 'web.art group',
          selected: false,
          available: true,
          className: ''
        },
        {
          label: 'Responsável sou eu',
          hash: 'Cliente',
          selected: false,
          available: true,
          className: ''
        },
        {
          label: 'Front-end',
          hash: 'Front-end',
          selected: false,
          available: true,
          className: ''
        },
        {
          label: 'Back-end',
          hash: 'Back-end',
          selected: false,
          available: true,
          className: ''
        },
        {
          label: 'SEO',
          hash: 'SEO',
          selected: false,
          available: true,
          className: ''
        },
        {
          label: 'Data Science',
          hash: 'Data Science',
          selected: false,
          available: true,
          className: ''
        },
        {
          label: 'Design',
          hash: 'Design',
          selected: false,
          available: true,
          className: ''
        },
        {
          label: 'Implantação',
          hash: 'Implantação de projetos',
          selected: false,
          available: true,
          className: ''
        },
      ]
    }
  },
  computed: {
    currentFilter() {
      return this.taskSectors.find(taskProduct => taskProduct.selected);
    }
  },
  methods: {
    changeCategoryFilter({ newOptions }) {
      this.taskSectors = newOptions;
    },
    changeSearch({ searchText }) {
      this.searchText = searchText;
    }
  }
};
</script>

<style scoped>
.checklist-titulo {
  font-size: 38px;
  font-family: "Rubik", sans-serif;
  color: #1c1c1c;
  margin-bottom: 4px;
}
.filter-wrapper {
  max-width: 200px;
  flex: 1 0;
}
.container-filtros {
  display: flex;
  gap: 47px;
  margin-left: auto;
  margin-bottom: 7px;
}
.filter-label {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 2px;
  color: #3D4756;
}
#checklistFiltro {
  z-index: 2;
}
</style>
<style>
#checklistFiltro .select-box-current {
  min-height: 42px;
  max-width: 200px;
}
#checklistFiltro .select-box {
  max-width: 200px;
}
#checklistFiltro .select-box-value {
  color: #1e232a;
}
#checklistFiltro .labelSelect {
  color: #3d4756;
}
</style>