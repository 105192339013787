<template>
  <div v-if="!loadingTasks" class="container-tarefas checklist-tasks">
    <table class="tarefas-table">
      <tr class="tarefas-titulo">
        <th>Etapa</th>
        <th>Tarefa</th>
        <th>Responsável</th>
        <th>Prazo</th>
        <th>Status</th>
        <th></th>
        <th>Detalhes</th>
        <th></th>
      </tr>
      <tr 
        v-for="(task, taskIndex) in filteredTasks" 
        :key="taskIndex"
        class="tarefas-informacoes"
      >
        <td>
          {{ task.step.nome }}
        </td>
        <td>
          <p>
            {{ task.nome }}
          </p>
        </td>
        <td>
          <p>
            <img src="@/assets/img/checklist/simbolo_wap.svg" alt="Wap store" />
            {{ task.setorResponsavel.nome }}
          </p>
        </td>
        <td>
          {{ task.dataInicio | dayMonth }} a
          {{ task.dataFim | dayMonth }}
        </td>
        <td :id="task.andamento" class="task-progress">
          <span>
            {{ task.andamento | progressFirstLetterUppercase }}
          </span>
        </td>
        <td>
          <CommonSelect 
            v-if="!task.step.concluido"
            :options="task.statusDisponiveis"
            @change="(payload) => changeTaskStatus(payload, taskIndex)"
            class="checklist-tasks__color-select"
          />
          <div v-else class="checklist-tasks__step-finished">
            {{ task.statusAtual.nome }}
          </div>
        </td>
        <td>
          <button 
            type="button" 
            @click="viewTaskDetails({ nome: task.nome, id: task.id })"
          >
            Ver detalhes
          </button>
        </td>
        <td>
          <div class="boxVisualizador">
            <template v-if="!task.visualizadoPor.length">
              Nenhuma visualização ainda.
            </template>
            <p 
              v-else
              v-for="(viewBy, viewIndex) in task.visualizadoPor"
              :key="`view${viewIndex}`"
            >
              {{ viewBy.nome }}
            </p>
          </div>
          <img src="@/assets/img/checklist/visualizar.svg" alt="Visualizar" />
        </td>
      </tr>
    </table>
    <ChecklistTasksForm
      :toggle="modalDetalhesTarefas"
      :taskDetail="taskDetail"
      @emitToggle="emitToggle"
      @changeTaskAtualizations="changeTaskAtualizations"
    />
  </div>
</template>
<script>
import CommonSelect from '../common/CommonSelect.vue';
import ChecklistTasksForm from './ChecklistTasksForm.vue';

export default {
  components: {
    CommonSelect,
    ChecklistTasksForm
  },
  props: {
    currentFilter: {
      type: Object,
      required: true
    },
    searchText: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingTasks: false,
      modalDetalhesTarefas: false,
      taskDetail: {
        task: {},
        updates: []
      }
    };
  },
  filters: {
    dayMonth(date) {
      return `${date.split('-')[2]}/${date.split('-')[1]}`;
    },
    progressFirstLetterUppercase(progress) {
      return `${progress.split('_').join(' ')[0].toUpperCase()}${progress.split('_').join(' ').slice(1)}`
    }
  },
  computed: {
    allProjectStepsAndTask() {
      return this.$store.state.projectModule.projectTasks.map(projectTasks => {
        return {
          ...projectTasks,
          statusDisponiveis: projectTasks.statusDisponiveis.map(status => ({
            ...status,
            taskId: projectTasks.id,
            projectStepId: projectTasks.idEtapaProjeto,
            label: status.nome,
            selected: status.id === projectTasks.statusAtual.id,
            available: true,
            stepFinish: this.$store.state.projectModule.project.etapas.find(step => (
              parseInt(step.id) === parseInt(projectTasks.idEtapaProjeto)
            )).concluido,
            //Remove espaços e adiciona "-", remove também acentos que vem da API.
            className: status.nome.toLowerCase().replaceAll(' ', '-').normalize('NFD').replaceAll(/[\u0300-\u036f]/g, "")
          })),
          step: this.$store.state.projectModule.project.etapas.find(step => (
            parseInt(step.id) === parseInt(projectTasks.idEtapaProjeto)
          ))
        };
      });
    },  
    filteredTasks() {
      let tasksList = this.allProjectStepsAndTask.filter(projectTask => {
        return projectTask.nome.toLowerCase().includes(this.searchText) || projectTask.step.nome.toLowerCase().includes(this.searchText);
      });

      if (this.currentFilter.hash !== 'all') {
        tasksList = tasksList.filter(projectTask => (
          projectTask.setorResponsavel.nome === this.currentFilter.hash
        ));
      }
      return tasksList;
    },
  },  
  methods: {
    viewTaskDetails({ nome, id }) {
      this.$axios
        .get(`/projetos/${this.$store.state.projectModule.currentProject.id}/tarefas/${id}/atualizacoes?limit=100&page=1`)
        .then(({ data }) => {
          this.taskDetail = {
            task: {
              id,
              nome
            },
            updates: data.data
          }
          document.body.style.overflow = 'hidden';
          this.$store.commit('toggleFundoEscuro', true);
          this.modalDetalhesTarefas = true;
        })
    },
    emitToggle(value) {
      this.$store.commit('toggleFundoEscuro', value);
      this.modalDetalhesTarefas = value;
    },
    async fetchTasksProject() {
      this.loadingTasks = true;
      if (!this.$store.state.projectModule.projectTasks.length) {
        await this.$store.dispatch(
          'fetchProjectTasksById', 
          this.$store.state.projectModule.currentProject.id
        );
        await this.$store.dispatch(
          'fetchProjectById', 
          this.$store.state.projectModule.currentProject.id
        );

        await Promise.all(
          this.$store.state.projectModule.projectTasks.map(async (projectTask) => {
            if (
              !(
                projectTask.visualizadoPor
                .some(viewFrom => (
                  parseInt(viewFrom.id) === parseInt(this.$store.state.userModule.myAccess[0].id)
                ))
              )
            ) { 
              // Verifica se o usuário já visualizou essa tarefa e realiza a visualização caso não tenha visualizado
              await this.$store.dispatch(
                'viewProjectTask', 
                { 
                  project: this.$store.state.projectModule.currentProject.id,
                  task: projectTask.id
                }
              );
            }
          })
        );
      }
      this.loadingTasks = false;
    },
    changeTaskStatus({ targetOption }) {
      if (!targetOption.stepFinish) {
        // Apenas tarefas de etapas não concluidas podem ser alterada o status
        this.$store.dispatch(
          'updateProjectTask',
          {
            project: this.$store.state.projectModule.currentProject.id, 
            task: targetOption.taskId,
            payload: {
              idStatus: targetOption.id, 
            }
          }
        ).then(() => {
          this.$store.commit('SET_PROJECTTASKS', this.$store.state.projectModule.projectTasks.map((projectTask) => {
            if (projectTask.id === targetOption.taskId) {
              return {
                ...projectTask,
                statusAtual: {
                  id: targetOption.id, 
                  nome: targetOption.nome
                }
              };
            }
  
            return projectTask;
          }));
  
          const allTasksFinished = this.$store.state.projectModule.projectTasks
            .filter(projectTask => parseInt(projectTask.idEtapaProjeto) === parseInt(targetOption.projectStepId))
            .every(projectTask => projectTask.statusAtual.nome === 'Concluído');
  
          if (allTasksFinished) {
            this.$store.dispatch(
              'updateProjectStep', 
              { 
                project: this.$store.state.projectModule.currentProject.id,
                payload: {
                  etapas: this.$store.state.projectModule.project.etapas.map(step => {
                    if (step.id === targetOption.projectStepId) {
                      return {
                        ...step,
                        concluido: true
                      }
                    }
    
                    return step;
                  }),
                }
              }
            );
          } 
          // else if (!allTasksFinished && targetOption.stepFinish) { 
          //   //A tarefa estava concluida, mas foi alterada para não concluida.
          //   this.$store.dispatch(
          //     'updateProjectStep', 
          //     { 
          //       project: this.$store.state.projectModule.currentProject.id,
          //       payload: {
          //         etapas: this.$store.state.projectModule.project.etapas.map(step => {
          //           if (step.id === targetOption.projectStepId) {
          //             return {
          //               ...step,
          //               concluido: false
          //             }
          //           }
    
          //           return step;
          //         }),
          //       }
          //     }
          //   );
          // }
        });
      }
    },
    changeTaskAtualizations(payload) {
      this.taskDetail = payload;
    }
  },
  created() {
    this.fetchTasksProject();
  }
};
</script>
<style>
/* ESTILOS SELECT DE STATUS */
.checklist-tasks__color-select {
  min-width: 185px;
}
.checklist-tasks__color-select.open {
  z-index: 2;
}
.checklist-tasks__color-select .select__first-state {
  justify-content: center;
}
.checklist-tasks__color-select .select__first-state img {
  display: none;
}
.checklist-tasks__color-select .select__current-value {
  font-size: 12px;
  font-weight: 500;
}
.checklist-tasks__color-select .concluido,
.checklist-tasks__color-select .aguardando-liberacao,
.checklist-tasks__color-select .pronto-para-iniciar,
.checklist-tasks__color-select .em-andamento,
.checklist-tasks__color-select .entregue,
.checklist-tasks__color-select .aguardando-aprovacao,
.checklist-tasks__color-select .aprovado,
.checklist-tasks__color-select .reprovado,

.checklist-tasks__color-select .concluido .select__current-value,
.checklist-tasks__color-select .aguardando-liberacao .select__current-value,
.checklist-tasks__color-select .pronto-para-iniciar .select__current-value,
.checklist-tasks__color-select .em-andamento .select__current-value,
.checklist-tasks__color-select .entregue .select__current-value,
.checklist-tasks__color-select .aguardando-aprovacao .select__current-value,
.checklist-tasks__color-select .aprovado .select__current-value,
.checklist-tasks__color-select .reprovado .select__current-value,

.checklist-tasks__color-select .select__item-option.concluido,
.checklist-tasks__color-select .select__item-option.aguardando-liberacao,
.checklist-tasks__color-select .select__item-option.pronto-para-iniciar,
.checklist-tasks__color-select .select__item-option.em-andamento,
.checklist-tasks__color-select .select__item-option.entregue,
.checklist-tasks__color-select .select__item-option.aguardando-aprovacao,
.checklist-tasks__color-select .select__item-option.aprovado,
.checklist-tasks__color-select .select__item-option.reprovado {
  color: #fff;
}

.checklist-tasks__color-select .concluido,
.checklist-tasks__color-select .select__item-option.concluido,
.checklist-tasks__color-select .concluido:hover,
.checklist-tasks__color-select .select__item-option.concluido:hover {
  background-color: #22942a;
}
.checklist-tasks__color-select .aguardando-liberacao,
.checklist-tasks__color-select .select__item-option.aguardando-liberacao,
.checklist-tasks__color-select .aguardando-liberacao:hover,
.checklist-tasks__color-select .select__item-option.aguardando-liberacao:hover {
  background-color: #A3A3A3;
}
.checklist-tasks__color-select .pronto-para-iniciar,
.checklist-tasks__color-select .select__item-option.pronto-para-iniciar,
.checklist-tasks__color-select .pronto-para-iniciar:hover,
.checklist-tasks__color-select .select__item-option.pronto-para-iniciar:hover {
  background-color: #9E63FF;
}
.checklist-tasks__color-select .em-andamento,
.checklist-tasks__color-select .select__item-option.em-andamento,
.checklist-tasks__color-select .em-andamento:hover,
.checklist-tasks__color-select .select__item-option.em-andamento:hover {
  background-color: #005FB2;
}
.checklist-tasks__color-select .entregue,
.checklist-tasks__color-select .select__item-option.entregue,
.checklist-tasks__color-select .entregue:hover,
.checklist-tasks__color-select .select__item-option.entregue:hover {
  background-color: #1CCE66;
}
.checklist-tasks__color-select .aguardando-aprovacao,
.checklist-tasks__color-select .select__item-option.aguardando-aprovacao,
.checklist-tasks__color-select .aguardando-aprovacao:hover,
.checklist-tasks__color-select .select__item-option.aguardando-aprovacao:hover {
  background-color: #F9A825;
}
.checklist-tasks__color-select .aprovado,
.checklist-tasks__color-select .select__item-option.aprovado,
.checklist-tasks__color-select .aprovado:hover,
.checklist-tasks__color-select .select__item-option.aprovado:hover {
  background-color: #36C5F0;
}

.checklist-tasks__color-select .reprovado,
.checklist-tasks__color-select .select__item-option.reprovado,
.checklist-tasks__color-select .reprovado:hover,
.checklist-tasks__color-select .select__item-option.reprovado:hover {
  background-color: #D22E2E;
}
.checklist-tasks__color-select .select__item-option {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}
.checklist-tasks__color-select .select__item-option:not(.notAccessible):hover {

}
/******************/
</style>
<style scoped>
.tarefas-table {
  border-spacing: 0px;
}
.tarefas-titulo {
  text-align: left;
  background: #f9f9f9;
}
.tarefas-titulo th {
  font-size: 12px;
  color: #3c4252;
  font-weight: bold;
  padding: 10px 0;
}
.tarefas-titulo th:first-child {
  padding-left: 10px;
}
.tarefas-informacoes {
  background: #ffffff;
}
.tarefas-informacoes td {
  border: 1px solid #efefef;
  border-left: none;
  border-right: none;
  font-size: 12px;
  color: #3c4252;
  padding: 10px 0;
}

.tarefas-informacoes td:first-child {
  padding-left: 10px;
  padding-right: 10px;
  border-left: 1px solid #efefef;
}

.tarefas-informacoes td:last-child {
  padding-right: 10px;
  border-right: 1px solid #efefef;
}
.tarefas-titulo th:nth-child(2) {
  width: 19%;
}
.tarefas-titulo th:nth-child(3) {
  width: 14.6%;
}
.tarefas-titulo th:nth-child(6) {
  width: 15.5%;
}
.tarefas-informacoes td:nth-child(6) {
  padding-right: 20px;
}

.tarefas-informacoes td:nth-child(2) p {
  margin-bottom: 0px;
  margin-right: 20px;
}
.tarefas-informacoes td:nth-child(3) p {
  display: flex;
  gap: 11px;
  margin-bottom: 0px;
  margin-right: 20px;
}
.tarefas-informacoes td:nth-child(6) .boxSelect {
  margin-right: 20px;
}
.tarefas-informacoes td:nth-child(7) button {
  color: #0077df;
  text-decoration: underline;
  cursor: pointer;
}
.tarefas-informacoes td {
  position: relative;
}
.tarefas-informacoes td:last-child {
  z-index: 1;
}
.task-progress span {
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 6px;
}
.task-progress span::before {
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 100%;
  display: block;;
}
.task-progress#em_dia span {
  color: #4aae51;
}
.task-progress#em_dia span::before {
  background: #4aae51;
}
.task-progress#em_atraso span {
  color: #D22E2E;
}
.task-progress#em_atraso span::before {
  background: #D22E2E;
}
.task-progress#em_atencao span {
  color: #F9A825;
}
.task-progress#em_atencao span::before {
  background: #F9A825;
}
.checklist-tasks__step-finished {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 41px;
  color: #FFF;
  background-color: #22942a;
  cursor: not-allowed;
  user-select: none;
  -webkit-user-drag: none;
}
.boxVisualizador {
  position: absolute;
  top: -30px;
  right: -8px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  box-shadow: 0px 0px 12px 0px rgba(72, 72, 72, 0.25);
  background: #fff;
  display: none;
}
.boxVisualizador::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #fff;
  position: absolute;
  top: 100%;
  right: 34px;
}
.boxVisualizador span {
  color: #3c4252;
  font-size: 12px;
}
.tarefas-informacoes td:last-child {
  cursor: pointer;
}
.tarefas-informacoes td:last-child:hover .boxVisualizador {
  display: block;
  animation-name: opacity;
  animation-duration: 0.8s;
}
.tarefas-informacoes td:last-child .boxVisualizador p {
  display: block;
  margin-bottom: 0;
  color: #3c4252;
  font-size: 12px;
  line-height: 1;
}
@keyframes opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>