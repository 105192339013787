<template>
  <div class="row container-breadCrumb">
    <div class="column large-12">
     <router-link 
      v-for="(item, index) in breadcrumb" 
      :key="index"
      class="breadCrumb" 
      :to="item.path"
    >
      {{ item.label }}

      <!-- MENOS PARA O ULTIMO ITEM DO BREADCRUMB -->
      <template v-if="index < breadcrumb.length - 1">
        /
      </template>
    </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonBreadcrumb',
  props: {
    breadcrumb: {
      type: Array, 
      required: true
    }
  }
}
</script>

<style scoped>
.container-breadCrumb {
  margin-bottom: 10px;
}
.breadCrumb {
  font-size: 12px;
  color: #1C1C1C;
}
.breadCrumb:not(:last-child) {
  color: #D0C8C8;
}
</style>