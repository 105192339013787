<template>
  <div class="boxBusca">
    <div class="labelBusca">
      Busque por:
    </div>
    <form @submit.prevent="changeSearch" class="busca-box">
      <input 
        v-model="search"
        type="text" 
        placeholder="O que você procura?" 
        name="busca" 
        id="busca"
      >
      <button type="submit">
        <img src="@/assets/img/checklist/lupa.svg" alt="Buscar" width="18" height="18">
      </button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'ChecklistSearch',
  data() {
    return {
      search: ''
    }
  },  
  watch: {
    search() {
      this.changeSearch();
    }
  },
  methods: {
    changeSearch() {
      this.$emit('change', { searchText: this.search.toLowerCase() });
    }
  }
}
</script>

<style scoped>
.boxBusca {
  max-width: 335px;
  width: 100%;
}
.labelBusca {
  font-size: 12px;
  color: #3D4756;
  margin-bottom: 2px;
}
.busca-box {
  max-width: 335px;
  width: 100%;
  border: 1px solid #EFEFEF;
  background: #fff;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.busca-box input {
  border: none;
  padding: 14px;
  color: #1E232A;
  max-width: 290px;
}
.busca-box button {
  width: 38px;
  height: 38px;
  background: #E85128;
  display: flex;
  align-items: center;
  justify-content: center;
}
.busca-box input::placeholder {
  color: #1E232A;
}
</style>