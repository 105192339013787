<template>
  <div class="container-formTarefas" @click.self="closeModal" :class="{ transitionActive: toggle }">
    <div class="formTarefas">
      <div class="header">
        <button type="button" class="closeModal" @click="closeModal">
          <img src="@/assets/img/close-form.svg" alt="Fechar aba" />
        </button>
        <div>
          <h2 class="formTarefas-titulo">
            {{ taskDetail.task.nome }}
          </h2>
        </div>
      </div>

      <div class="formTarefas-items">
        <div 
          v-if="!taskDetail.updates.length"
          class="form-task__nothing-commet"
        >
          Nenhum comentário para exibir, seja o primeiro a comentar.
        </div>  
        <div 
          v-for="(update, index) in taskDetail.updates" 
          :key="index"
          class="formTarefas-item" 
        >
          <span class="horario">
            {{ update.createdAt | datetimeToTime }}
          </span>
          <p class="conteudo">
            {{ update.conteudo }}
          </p>
          <span class="responsavel">
            {{ update.autor.nome }}
          </span>
        </div>
      </div>
      <div class="formTarefas-form">
        <form @submit.prevent="postTaskAtualization">
          <textarea
            v-model="comment"
            name=""
            id=""
            placeholder="Seu comentário aqui..."
          ></textarea>
          <div>
            <label for="files">
              <img src="@/assets/img/file.svg" alt="" /> 
              Anexar arquivos
            </label>
            <input id="files" style="visibility: hidden" type="file" />
            <button :class="{ disabled: !validForm || loading }" type="submit">
              Enviar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    toggle: {
      type: Boolean,
      required: true
    },
    taskDetail: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      emitToggle: false,
      comment: '',
      loading: false
    };
  },
  filters: {
    datetimeToTime(datetime) {
      const time = datetime.split(' ')[1];
      const [ hour, minute ] = time.split(':');
      return `${hour}h${minute}`;
    }
  },    
  computed: {
    validForm() {
      return this.comment.length > 3;
    }
  },
  methods: {
    closeModal() {
      this.$emit("emitToggle", this.emitToggle);
      document.body.style.overflow = 'unset';
    },
    async postTaskAtualization() {
      if (!this.loading) {
        this.loading = true;

        await this.$axios
          .post(
            `/projetos/${this.$store.state.projectModule.currentProject.id}/tarefas/${this.taskDetail.task.id}/atualizacoes`,
            {
              conteudo: this.comment,
              privada: false
            }
          );

        await this.$axios
          .get(`/projetos/${this.$store.state.projectModule.currentProject.id}/tarefas/${this.taskDetail.task.id}/atualizacoes?limit=100&page=1`)
          .then(({ data }) => {
            this.$emit('changeTaskAtualizations', {
              task: {
                id: this.taskDetail.task.id,
                nome: this.taskDetail.task.nome
              },
              updates: data.data
            })
          });
          
        this.comment = '';
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.container-formTarefas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  transform: translate(100%);
  transition: 800ms;
}
.formTarefas {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  background: #fff;
  height: 100vh;
  width: 651px;
  padding: 40px 15px;
  overflow: hidden;
}
.closeModal {
  position: absolute;
  top: 13px;
  left: 15px;
}
.formTarefas-titulo {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 25px;
  color: #3c4252;
}
.formTarefas-item {
  border: 1px solid #efefef;
  padding: 15px;
}
.horario,
.responsavel {
  display: block;
  font-size: 12px;
  color: rgba(60, 66, 82, 50%);
  margin-bottom: 8px;
}
.conteudo {
  font-size: 14px;
  color: #3c4252;
}
.conteudo a {
  color: #5390eb;
  text-decoration: underline;
  margin-bottom: 13px;
}
.formTarefas-items {
  flex: 1 0;
  overflow-y: auto;
  margin-bottom: 50px;
}
.form-task__nothing-commet {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 12px;
  color: #3c4252;
}
.formTarefas-form textarea {
  border-color: #959494;
  border-radius: 0px;
  color: #3c4252;
  min-height: 172px;
  padding: 15px 25px;
  margin-bottom: 5px;
}
.formTarefas-form div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.formTarefas-form label {
  font-size: 14px;
  color: #000;
  cursor: pointer;
}
.formTarefas-form button {
  background: #e85128;
  min-height: 35px;
  width: 200px;
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.formTarefas-form button.disabled {
  opacity: 0.3;
}
#files {
  display: none;
}
.transitionActive {
  transition: 800ms;
  transform: translate(0);
}
</style>